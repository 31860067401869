@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: thin;
  scrollbar-color: #5f39ff #0e0e0e;
  
}

.grid-for-blog {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column for small screens */
}

@media (min-width: 1024px) {
  .grid-for-blog {
    grid-template-columns: 30% 70%; /* Adjust to 40% - 60% for medium and larger screens */
  }
}


.grid-for-main-blog{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 768px) {
  .grid-for-main-blog {
      grid-template-columns: 1fr;
  }
}

.interactive-canvas {
  cursor: grab;
}

.interactive-canvas:active {
  cursor: grabbing;
}



.App{
  overflow-x: hidden;
}
.contact-bg {
  background-image: url(./Components/Assets//h2-contact-bg.webp);
  background-repeat: no-repeat;
}
.bg-circle {
  background-color: rgb(225 225 255 / 60%) !important;
}
.padding-about {
  padding: 1px;
}
.bg-image {
  background-image: url(./Components/Assets/bg-light2.webp);
  background-repeat: no-repeat;
}
.service-bg {
  background-image: url(./Components//Assets/wave-bg.webp);
}
.w-cards {
  width: 32%;
  border-radius: 40px 8px;
}
.border-custom {
  border-radius: 8px 40px;
}
.w-cards:hover {
  box-shadow: 0 15px 39px -11px #46638e45;
  transform: translate(0, -5px);
}
.custom-shadow:hover {
  box-shadow: 0 15px 39px -11px #46638e45;
  transform: translate(0, -5px);
}
.shadow-location {
  box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
    0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
    0 25px 40px -30px rgba(0, 0, 0, 0.2);
}
.shadow-location:hover {
  box-shadow: 0 15px 39px -11px #46638e45;
  transform: translate(0, -5px);
}
.dg-bg2 {
  background: linear-gradient(
    45deg,
    #ff0048 0%,
    #ff3870 50%,
    #c300c3 100%
  ) !important;
}
.bg-gradient12 {
  background: linear-gradient(to right, #9c27b0 0, #673ab7 100%);
}
.info-card {
  padding: 30px;
  margin: 20px 0;
  box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
    0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
    0 25px 40px -30px rgba(0, 0, 0, 0.2);
}
span.circle {
  right: -130px;
  width: 200px;
  height: 200px;
}
span.circle,
span.circle2 {
  background: rgba(255, 255, 255, 0.2);
  display: block;
  position: absolute;
  border-radius: 50%;
  top: -10%;
}

.lnk,
button {
  position: relative;
  overflow: hidden;
}
button {
  -webkit-transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
  -o-transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
  transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.bg-b {
  background-image: linear-gradient(
    to right,
    #673ab7 0,
    #e91e63 36%,
    #e91e63 65%,
    #673ab7 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.image-block img {
  box-shadow: -20px 20px 35px #dcdcdc, 20px -40px 35px #fff;
}
.animation-custom {
  animation: border-transform 6s linear infinite;
  border: 8px solid #fbfbfb;
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}


.shadow-main{
  box-shadow: -30px 30px 60px #f2f2f2, 30px -30px 60px #fff;
}
.image-shadow{
  box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, .059), 0 8.2px 8.9px -30px rgba(0, 0, 0, .071), 0 25px 40px -30px rgba(0, 0, 0, .2);
}

.text-center.opacity-main:hover .inner-div {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}
.active {
  background: linear-gradient(90deg, #20d9a1 0%, #5f39ff 100%);
  background-clip: text;
  color: transparent;
}
.service-section{
  background-image: url(../src/Components/Assets/dot-shape.webp);
}
.bg-image-third{
  background-image: url(../src/Components/Assets/banner.webp);
  background-size: cover;
}
.bg-image-second{
  background-image: url(../src/Components/Assets/back.webp);
  background-size: cover;
}

.list-none li{
  margin-bottom: 10px
}
.col {
  box-shadow: 16px 1px 8px -14px rgba(0, 0, 0, .75);
}

.key-points li:before {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  top: 9px;
  background: #ffc627;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  color: #333;
}
.key-points li {
  padding: 8px 0 8px 35px;
  position: relative;
  line-height: 28px;
  font-size: 17px;
}
.key-points li:before, .ul-check li:before {
  content: "\f00c";
}

.quality-box {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, .75);
  min-height: 150px;
  height: 150px;
  border-radius: 5px;
}




.custom-background{
  background-image: url(./Components/Assets/656702.webp);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.full-name{
  background-repeat: no-repeat;
background-position: 10px 2px;
    background-size: 32px !important;
}
.form-block input:focus, .form-block textarea:focus ,.form-block select:focus {
  border-bottom: 2px solid #ff1f8e ;
  outline: none;
 
}
.form-block input, .form-block textarea ,.form-block select{
  box-shadow: 0 0 10px 0 #d5d2ee;
 
}
.guZdik {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 48px;
  border: 2px dashed rgb(6, 88, 194);
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-flex: 0;
  flex-grow: 0;
}
input.border-custom-second{
  border: 2px dashed rgb(6, 88, 194) !important;
}

.our-team{
    transition: all .4s;
    box-shadow: 0 0 7px 0 rgb(43 52 59 / 8%);
    border-radius: 5px;
    overflow: hidden;
}
.team-content{
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 2px solid rgb(6, 88, 194);
}
.zoom-image {
  transition: transform 0.3s ease;
  overflow: hidden;
  
}

.zoom-image:hover {
  transform: scale(1.09);
  
}
.social-icons {
  bottom: 100%; /* Position the <ul> just above the button */
}
.custom-close-button{
  font-size: 31px;

  font-weight: 700;
}
.custom-button{
  font-size: 31px;
  font-weight: 700;
}
.shadow-custom-second{
  box-shadow: none !important;
}

.dotted-border-checkbox {
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  border: 2px dotted rgb(6, 88, 194); /* Adjust border color and width as needed */
  display: inline-block;
  border-radius: 5px;
  position: relative;
}

.dotted-border-checkbox:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px; /* Adjust as needed */
  height: 12px; /* Adjust as needed */
  background-color: #000; /* Adjust checkmark color as needed */
  transform: translate(-50%, -50%);
  border-radius: 4px;
}
