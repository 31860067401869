
@tailwind base;
@tailwind components;
@tailwind utilities;





.nav-logo{
    width: 280px;
}


.coustom_margin{
  margin: 0 !important;
}
@media only screen and (max-width: 900px) {
  .sticky-nav {
    position: fixed !important;
    margin:  0;
    top: 0;
    width: 100%;
    padding: 3px 0;
    border-radius: 0;
    z-index: 1000;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional, adds a shadow */
  
  }
    .nav-logo{
        width: 160px;
    }
    .sticky-nav {
      width: 100%  !important;
    
    }
    .w-cards {
      width: 100% !important;
    }
    .nav-width {
        /* width: 92% !important; */
        margin-left: 20px;
      }
      .hero-images .image-one {
        border-radius: 200px 200px 0 0;
        width: 100% !important;
        height: 50% !important;
    }
    .hero-images::before {
        width: 100% !important;
        height: 50% !important;
    }
    .hero-images-two .image-two {
        border-radius: 0 0 200px 200px;
        width: 100% !important;
        height: 50% !important;
    }
    .hero-images-two::before {
        width: 100% !important;
        height: 50% !important;
    }
    .about-circle-box {
        height: 100px !important;
        width: 100px !important;
        padding: 10px !important;
    }
    .hero-images .shape-1 {
        top: -26px !important;
        right: -10px !important;
        height: 95px !important;
    }
    .hero-images-two .shape-2 {
        left: -22px !important;
        top: 192px !important;
        height: 75px !important;
    }
    .hero-images-two {
        height: 420px !important;
    }
    .hero-images {
        height: 420px !important;
    }


    .services-list-item {
      display: block;
      padding-left: 10px;
      padding-right: 10px;
  }
      .services-list-item .flex img {
        margin-right: 0;
        margin-bottom: 10px;
      }
      .services-list-item .flex h3 {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }
      .services-list-item .service-list {
        margin-top: 10px;
        font-size: 14px;
      }
      .services-list-item .service-list li {
        margin-right: 10px;
        margin-bottom: 5px;
      }
      .services-list-item .service-list li:last-child {
        margin-right: 0;
      }
      .services-list-item .flex gap-3 {
        width: 100%;
      }.card-data{
        display: block;
      }

      .bg-shape-dez::after {
        right: -10px !important;
    }
    .Business-domain {
      padding-top: 0 !important;
      padding-bottom: 50px !important;
  }
  .testimonial-card{
    height: 400px;
    margin-top: 20px;
  }
  .slick-dots {
    position: absolute !important;
    bottom: -14px !important;
}
.about-width{
  width: 100%;
  margin-bottom: 20px;
  border-color: #5F39FD !important;
}
.col {
  box-shadow: 0 15px 8px -14px rgba(0, 0, 0, .75);
  margin-bottom: 15px;
}
.quality-box {
  height: 175px !important;
}
.mobile-border li{
  border-bottom: 1px solid gray;
  padding: 10px;
}

.mobile-bor a:not(:last-child) {
  border-bottom: 1px solid gray;
  padding: 10px;
}
.custom-background {
  background-image: url(http://localhost:3000/static/media/656702.6788dd1389b30dabbae4.webp);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

}
.index-main{
  z-index: 999;
}
.sticky-nav {
  position: fixed !important;
  margin:  0;
  top: 0;
  width: 100%;
  padding: 3px 0;
  border-radius: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional, adds a shadow */

}
.custom-width{
  width: 600px;
}

